<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilStorage" />
          Danh sách Mã dự thưởng
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click.prevent="
            (e) => {
              importing = true;
            }
          "
        >
          <CIcon name="cilCloudUpload" />
          Import Mã dự thưởng
        </CButton>
      </CCardHeader>
      <CCardBody>
        <div class="form-row">
          <CCol md="4">
            <div class="form-group">
              <div class="form-row">
                <label class="col-form-label col-4 text-right"
                  >Chương trình</label
                >
                <v-select
                  v-model="programId"
                  :options="programs"
                  :reduce="(p) => p.Id"
                  label="Name"
                  placeholder="Chương trình"
                  class="col-8"
                />
              </div>
            </div>
          </CCol>
          <CCol md="2">
            <CSelect
              label="Trạng thái"
              placeholder="Trạng thái"
              :value.sync="codeStatus"
              :options="[
                { value: null, label: 'Tất cả' },
                ...Object.keys(statusList).map((_) => {
                  return {
                    value: _,
                    label: statusList[_],
                  };
                }),
              ]"
              :horizontal="{
                label: 'col-5 text-right',
                input: 'col-7',
              }"
            />
          </CCol>
          <CCol md="3">
            <CInput
              label="Mã dự thưởng"
              placeholder="Mã dự thưởng"
              v-model="codeName"
              :horizontal="{
                label: 'col-5 text-right',
                input: 'col-7',
              }"
              @keyup.enter="doFilter"
            />
          </CCol>
          <CCol md="3" class="text-center">
            <CButton color="primary" class="mr-2 mb-3" @click="doFilter">
              <CIcon name="cil-search" custom-classes="c-icon m-0" />
              Tìm kiếm
            </CButton>

            <CButton color="secondary" class="mb-3" @click="clear">
              <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
              Đặt lại
            </CButton>
          </CCol>
        </div>
        <odata-table
          ref="lstCode"
          url="odata/LuckyCode"
          :filter="filter"
          select="Id,Code,SerialNo,SecretNo,Status,CreatedAt"
          expand="Program($select=Name),JoiningRewards($top=0;$count=true)"
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            ProgramName: {
              field: 'Program/Name',
              display: 'Chương trình',
              sortable: true,
              style: 'min-width: 120px',
            },
            Code: {
              field: 'Code',
              display: 'Mã dự thưởng',
              sortable: true,
              style: 'min-width: 120px',
            },
            SerialNo: {
              field: 'SerialNo',
              display: 'Số serial',
              sortable: true,
              style: 'min-width: 120px',
            },
            SecretNo: {
              field: 'SecretNo',
              display: 'Mã không cào',
              sortable: true,
              style: 'min-width: 120px',
            },
            IsUsed: {
              field: 'IsUsed',
              display: 'Đã dùng',
              sortable: false,
              style: 'min-width: 50px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            CreatedAt: {
              field: 'CreatedAt',
              display: 'Ngày tạo',
              sortable: true,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstCode.page - 1) * pageSize }}.</td>
              <td>
                {{ row.Program.Name }}
              </td>
              <td>
                {{ row.Code }}
              </td>
              <td>
                {{ row.SerialNo }}
              </td>
              <td>
                {{ row.SecretNo }}
              </td>
              <td>
                <CIcon
                  name="cilCheck"
                  custom-classes="c-icon c-icon-lg text-danger"
                  v-if="row.JoiningRewards && row['JoiningRewards@odata.count']"
                />
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  $moment
                    .utc(row.CreatedAt)
                    .utcOffset("-08:00")
                    .format("DD/MM/YY HH:mm")
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <importForm
      :showing.sync="importing"
      :programs="programs"
      @reload="$refs.lstCode.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import importForm from "./Import.vue";

export default {
  name: "LuckyCode",
  components: {
    odataTable,
    importForm,
  },
  data() {
    return {
      importing: false,
      codeId: 0,
      pageSize: 10,
      programs: [],
      programId: null,
      codeStatus: null,
      statusList: this.$const.STATUS_TEXT,
      codeName: null,
      filters: null,
    };
  },
  async mounted() {
    this.programs = await this.loadPrograms();
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },
    doFilter() {
      let filters = [];
      this.programId && filters.push(`ProgramId eq ${this.programId}`);
      this.codeStatus && filters.push(`Status eq ${this.codeStatus}`);
      this.codeName && filters.push(`contains(Code,'${this.codeName}')`);
      filters.join(" and ");

      if (JSON.stringify(this.filters) != JSON.stringify(filters)) {
        this.filters = filters;
      } else {
        this.$refs.lstCode.loadData();
      }
    },
    clear() {
      this.programId = null;
      this.codeStatus = this.$const.STATUS.Active.toString();
      this.codeName = null;
    },
    async loadPrograms() {
      let result = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/LuckyProgram", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          $orderby: "CreatedAt desc",
        },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return result;
    },
  },
};
</script>
