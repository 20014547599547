var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilStorage"}}),_vm._v(" Danh sách Mã dự thưởng ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
            _vm.importing = true;
          })($event)}}},[_c('CIcon',{attrs:{"name":"cilCloudUpload"}}),_vm._v(" Import Mã dự thưởng ")],1)],1),_c('CCardBody',[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"md":"4"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-row"},[_c('label',{staticClass:"col-form-label col-4 text-right"},[_vm._v("Chương trình")]),_c('v-select',{staticClass:"col-8",attrs:{"options":_vm.programs,"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chương trình"},model:{value:(_vm.programId),callback:function ($$v) {_vm.programId=$$v},expression:"programId"}})],1)])]),_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Trạng thái","value":_vm.codeStatus,"options":[
              { value: null, label: 'Tất cả' } ].concat( Object.keys(_vm.statusList).map(function (_) {
                return {
                  value: _,
                  label: _vm.statusList[_],
                };
              }) ),"horizontal":{
              label: 'col-5 text-right',
              input: 'col-7',
            }},on:{"update:value":function($event){_vm.codeStatus=$event}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Mã dự thưởng","placeholder":"Mã dự thưởng","horizontal":{
              label: 'col-5 text-right',
              input: 'col-7',
            }},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doFilter($event)}},model:{value:(_vm.codeName),callback:function ($$v) {_vm.codeName=$$v},expression:"codeName"}})],1),_c('CCol',{staticClass:"text-center",attrs:{"md":"3"}},[_c('CButton',{staticClass:"mr-2 mb-3",attrs:{"color":"primary"},on:{"click":_vm.doFilter}},[_c('CIcon',{attrs:{"name":"cil-search","custom-classes":"c-icon m-0"}}),_vm._v(" Tìm kiếm ")],1),_c('CButton',{staticClass:"mb-3",attrs:{"color":"secondary"},on:{"click":_vm.clear}},[_c('CIcon',{attrs:{"name":"cil-x-circle","custom-classes":"c-icon m-0"}}),_vm._v(" Đặt lại ")],1)],1)],1),_c('odata-table',{ref:"lstCode",attrs:{"url":"odata/LuckyCode","filter":_vm.filter,"select":"Id,Code,SerialNo,SecretNo,Status,CreatedAt","expand":"Program($select=Name),JoiningRewards($top=0;$count=true)","sortBy":"CreatedAt desc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          ProgramName: {
            field: 'Program/Name',
            display: 'Chương trình',
            sortable: true,
            style: 'min-width: 120px',
          },
          Code: {
            field: 'Code',
            display: 'Mã dự thưởng',
            sortable: true,
            style: 'min-width: 120px',
          },
          SerialNo: {
            field: 'SerialNo',
            display: 'Số serial',
            sortable: true,
            style: 'min-width: 120px',
          },
          SecretNo: {
            field: 'SecretNo',
            display: 'Mã không cào',
            sortable: true,
            style: 'min-width: 120px',
          },
          IsUsed: {
            field: 'IsUsed',
            display: 'Đã dùng',
            sortable: false,
            style: 'min-width: 50px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstCode.page - 1) * _vm.pageSize)+".")]),_c('td',[_vm._v(" "+_vm._s(row.Program.Name)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.Code)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.SerialNo)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.SecretNo)+" ")]),_c('td',[(row.JoiningRewards && row['JoiningRewards@odata.count'])?_c('CIcon',{attrs:{"name":"cilCheck","custom-classes":"c-icon c-icon-lg text-danger"}}):_vm._e()],1),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .utcOffset("-08:00") .format("DD/MM/YY HH:mm"))+" ")])])})}}])})],1)],1),_c('importForm',{attrs:{"showing":_vm.importing,"programs":_vm.programs},on:{"update:showing":function($event){_vm.importing=$event},"reload":function($event){return _vm.$refs.lstCode.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }