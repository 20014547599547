<template>
  <CModal
    title="Import Mã dự thưởng"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol>
            <div class="form-group">
              <div v-if="loading" class="spinner-border" role="status">
                <span class="sr-only">Đang tải...</span>
              </div>
              <input
                v-else-if="!importing"
                class="form-control"
                id="formFileSm"
                type="file"
                @change="handleFileAsync"
              />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <div class="table-responsive">
              <table class="table table-hover table-sm">
                <thead>
                  <tr>
                    <th>Chọn</th>
                    <th>STT</th>
                    <th>Chương trình</th>
                    <th>Mã dự thưởng</th>
                    <th>Số serial</th>
                    <th>Mã không cào</th>
                    <th>Trạng thái</th>
                    <th>Thông báo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(luckyCode, i) in luckyCodes"
                    :key="`luckyCode-${i}`"
                  >
                    <td>
                      <CSwitch
                        class="mx-1"
                        color="info"
                        size="sm"
                        variant="opposite"
                        shape="pill"
                        :checked.sync="luckyCode.Selected"
                      />
                    </td>
                    <td>{{ i + 1 }}</td>
                    <td>{{ luckyCode.ProgramName }}</td>
                    <td>{{ luckyCode.Code }}</td>
                    <td>{{ luckyCode.SerialNo }}</td>
                    <td>{{ luckyCode.SecretNo }}</td>
                    <td>
                      <CBadge
                        :color="
                          luckyCode.Status.toLowerCase() == 'active'
                            ? 'success'
                            : 'secondary'
                        "
                      >
                        {{ luckyCode.Status }}
                      </CBadge>
                    </td>
                    <td>
                      <div v-if="luckyCode.Importing">
                        Đang xử lý...
                      </div>
                      <div v-if="luckyCode.Responses">
                        <div
                          v-for="(response, j) in luckyCode.Responses"
                          :key="`response-${i}-${j}`"
                          :class="
                            `text-${
                              response.Type == 'Error'
                                ? 'danger'
                                : response.Type == 'Warning'
                                ? 'warning'
                                : 'success'
                            }`
                          "
                        >
                          - {{ response.Message }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton
        class="btn btn-primary mr-2"
        @click="updateLuckyCode"
        :disabled="importing"
      >
        <CIcon name="cil-save" />
        Cập nhật mã (bulk)
      </CButton>
      <CButton
        class="btn btn-success"
        @click="importLuckyCode"
        :disabled="importing"
      >
        <CIcon name="cil-save" />
        Tạo mã (bulk)
      </CButton>
      <div class="d-inline-block font-italic" v-if="importing">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: ["showing", "programs"],
  data() {
    return {
      detailModal: false,
      importing: false,
      luckyCodes: [],
      loading: false,
    };
  },
  watch: {
    showing(val) {
      this.detailModal = val;
      this.luckyCodes = [];
    },
    detailModal(val) {
      this.$emit("update:showing", val);
    },
  },
  methods: {
    async importLuckyCode() {
      let importedCodes = this.luckyCodes.filter((_) => _.Selected);
      if (importedCodes && importedCodes.length) {
        if (confirm("Bạn có chắc chắn muốn tạo mới hàng loạt dữ liệu?")) {
          this.importing = true;

          await this.$func.asyncForEach(importedCodes, async (importedCode) => {
            importedCode.Importing = true;
            try {
              if (importedCode.ProgramName) {
                let program = this.programs.find(
                  (_) => _.Name == importedCode.ProgramName
                );
                if (program) {
                  let resp = await this.$http.post(`odata/LuckyCode`, {
                    ProgramId: program.Id,
                    Code: importedCode.Code,
                    SerialNo: importedCode.SerialNo,
                    SecretNo: importedCode.SecretNo,
                    Status:
                      importedCode.Status &&
                      importedCode.Status.toLowerCase() == "active"
                        ? this.$const.STATUS.Active
                        : this.$const.STATUS.InActive,
                  });
                  if (resp && resp.status == 201) {
                    importedCode.Importing = false;
                    importedCode.Selected = false;
                    importedCode.Responses = [
                      {
                        Type: "Success",
                        Message: "Tạo Thành công!",
                      },
                    ];
                  }
                } else {
                  importedCode.Importing = false;
                  importedCode.Responses = [
                    {
                      Type: "Error",
                      Message: "Sai thông tin Chương trình",
                    },
                  ];
                }
              } else {
                importedCode.Importing = false;
                importedCode.Responses = [
                  {
                    Type: "Error",
                    Message: "Thiếu thông tin Chương trình",
                  },
                ];
              }
            } catch (error) {
              importedCode.Importing = false;
              let message = error;
              if (error?.response?.data?.error?.message) {
                message = this.$const.MESSAGE[
                  error.response.data.error.message
                ];
              }
              importedCode.Responses = [
                {
                  Type: "Error",
                  Message: message,
                },
              ];
            }
          });

          this.importing = false;
          this.$emit("reload");
        }
      }
    },

    async updateLuckyCode() {
      let importedCodes = this.luckyCodes.filter((_) => _.Selected);
      if (importedCodes && importedCodes.length) {
        if (confirm("Bạn có chắc chắn muốn cập nhật hàng loạt dữ liệu?")) {
          this.importing = true;

          await this.$func.asyncForEach(importedCodes, async (importedCode) => {
            importedCode.Importing = true;
            try {
              let resp = await this.$http.patch(
                `odata/LuckyCode/${importedCode.Code}`,
                {
                  SerialNo: importedCode.SerialNo,
                  SecretNo: importedCode.SecretNo,
                  Status:
                    importedCode.Status &&
                    importedCode.Status.toLowerCase() == "active"
                      ? this.$const.STATUS.Active
                      : this.$const.STATUS.InActive,
                }
              );
              if (resp && resp.status == 204) {
                importedCode.Importing = false;
                importedCode.Selected = false;
                importedCode.Responses = [
                  {
                    Type: "Success",
                    Message: "Cập nhật thành công!",
                  },
                ];
              }
            } catch (error) {
              importedCode.Importing = false;
              let message = error;
              if (error?.response?.data?.error?.message) {
                message = this.$const.MESSAGE[
                  error.response.data.error.message
                ];
              }
              importedCode.Responses = [
                {
                  Type: "Error",
                  Message: message,
                },
              ];
            }
          });

          this.importing = false;
          this.$emit("reload");
        }
      }
    },

    async handleFileAsync(e) {
      try {
        this.luckyCodes = [];
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
          this.loading = true;
          const reader = new FileReader();

          reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 0 });
            if (data && data.length) {
              let rows = data.filter((_) => _["Mã dự thưởng"]);
              this.luckyCodes =
                rows && rows.length
                  ? rows.map((_) => {
                      return {
                        Selected: true,
                        ProgramName: _["Tên chương trình"],
                        Code: _["Mã dự thưởng"].toUpperCase(),
                        SerialNo: _["Số Serial"].toString(),
                        SecretNo: _["Mã không cào"].toString(),
                        Status: _["Trạng thái"],
                        Responses: [],
                        Importing: false,
                      };
                    })
                  : [];
            }
            this.loading = false;
          };

          reader.readAsBinaryString(file);
        }
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
